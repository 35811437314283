import api from '../api'
/**
 * 标签台账
 */
export class StandingBookListApi extends api {
    exportBook(data){
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/exportBook`,
        responseType: 'blob',
        method: 'post',
        data
      })
   }
    exportBookZip(data){
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/exportBookZip`,
        responseType: 'blob',
        method: 'post',
        data
      })
    }
}

export default new StandingBookListApi('standingBookList')