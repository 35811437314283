<template>
  <list-condition-template ref="table" :rows="rows" :total="total" 
   @page-info-change="handlePageInfoChange" v-model:listQuery="listQuery" :batch-column="true" @selected="selectedStandingBooks"
   :index-column="true" :defaultSort="{prop:'updateTime',order:'descending'}">
    <template #condition>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="请输入订单批次查询" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <div class="demo-date-picker">
          <div class="block">
            <span class="demonstration">筛选日期：</span>
            <el-date-picker
              clearable
              value-format="YYYY-MM-DD"
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px;">
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row justify="end">
        <el-button @click="mergeExportBook" type="success" size="mini" v-waves>导出合并台账</el-button>
        <el-button class="filter-item" @click="exportBookBatch" type="success" size="mini" v-waves>导出台账</el-button>
      </el-row>
    </template>
    <template #columns>
        <el-table-column min-width="150px" label="订单批次" align="center" property="orderBatch">
          <template  #default="scope">
            <span>{{scope.row.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签下单台账" align="center" property="placeOrder">已生成</el-table-column>
        <el-table-column min-width="150px" label="标签接收台账" align="center" property="receiptOrder">
          <template #default="scope">
            <span>{{scope.row.orderStatus && scope.row.orderStatus.name==='RECEIPT' ? '已生成':''}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签分发台账" align="center" property="receiptOrder">
          <template #default="scope">
            <span>{{scope.row.distributeState ? '已生成':''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px" class-name="fixed-width" align="center">
          <template  #default="scope">
            <el-button type="primary" size="mini" @click="handleShow(scope.row)" v-waves>查看</el-button>
            <!-- <el-button @click="exportBook(scope.row)" type="success" size="mini" v-waves>导出台账</el-button> -->
          </template>
        </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import standingBookListApi from '@/apis/standingBook/standingBookList'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      standingBooks: [],
      rows: null,
      total: 0,
      valueDate:undefined,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null,
          startTime: undefined,
          endTime: undefined,
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      }
    }
  },
  filters: {
    /**
     * 表格行显示状态过滤
     * @param {*} show 显示状态
     */

  },
  methods: {
    selectedStandingBooks(data){
      this.standingBooks = data
    },
    /**
     * 加载数据
     */
    getList (pageInfo) {
      standingBookListApi.getPaged(this.listQuery).then(response=>{
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter (pageInfo) {
      if(this.valueDate){
        this.listQuery.condition.startTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startTime = undefined
        this.listQuery.condition.endTime = undefined
      }
      this.getList(pageInfo)
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.getList(pageInfo)
    },
    handleShow(row){
      this.$router.pushTo(this.$router.routesConfig.standingBookDetails, row.id)
    },
    exportBookBatch(){
      if(!this.standingBooks ||this.standingBooks.length < 1){
        this.$message.warning("请勾选台账数据")
      }else{
        var orderIds = this.standingBooks.map(order=>{
          return order.id
        })
        standingBookListApi.exportBookZip(orderIds).then(resp=>{
          saveAs(resp.data, `台账明细.zip`)
        }).catch(res => {
          this.$message.error("无法下载")
        })
      }
    },
    mergeExportBook(){
      if(!this.standingBooks ||this.standingBooks.length < 1){
        this.$message.warning("请勾选台账数据")
      }else{
        var orderIds = this.standingBooks.map(order=>{
          return order.id
        })
        standingBookListApi.exportBook(orderIds).then(resp=>{
          saveAs(resp.data, `台账合并明细.xlsx`)
        }).catch(res => {
          this.$message.error("无法下载")
        })
      }
    },
    exportBook(row){
      standingBookListApi.exportBook([row.id]).then(resp=>{
        saveAs(resp.data, `台账明细.xlsx`)
      }).catch(res => {
        this.$message.error("无法下载")
      })
    }
  }
}
</script>
